<template>
    <div class="fixed top-1/2 left-1/2 transform50p w-full h-full bg-gray-800 z-50">
        <div v-if="imgbase64">
            <img  :src="imgbase64" class="absolute m-auto top-1/2 left-1/2 transform50p mwh90" alt="">
        </div>
        <div v-else class="absolute m-auto top-1/2 left-1/2 transform50p">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <a @click.prevent="closeimg()" href="/" class="fixed top-5 right-5 pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </a>
    </div>
</template>

<script>
export default {
    props:['img','closeimg'],
    data(){
        return {
            imgbase64: null
        }
    },
    mounted(){
        //this.$http.get(process.env.VUE_APP_API_ROOT+'/image?location='+this.img).then(this.handleimg).catch(this.handleerr);
        this.imgbase64 = process.env.VUE_APP_BASE_URL + this.img.replaceAll('\\','/');
    },
    methods:{
        handleimg(res){
            if(res.status == 200){
                this.imgbase64 = res.data.base64;
                console.log()
            }
        },
        handleerr(err){
            console.log(err)
        },
    }
}
</script>

<style scoped>
.transform50p{
    transform: translate(-50%,-50%);
}
.mwh90{
    max-width: 90%;
    max-height: 90%;
}
</style>