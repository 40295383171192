<template>
	<div>
		<ul
			class="flex justify-center items-center my-4 bg-gray-800 py-2 flex-wrap"
		>
			<li
				class="cursor-pointer py-2 px-4 text-gray-500 border-b-8"
				v-for="(tab, index) in tabs"
				:key="index"
				:class="
					activeTab === index ? 'text-blue-100 border-blue-500' : ''
				"
				@click="activeTab = index"
			>
				{{ tab }}
			</li>
		</ul>
		<div class="min-w-screen bg-gray-800 flex flex-col gap-9 px-5 py-10">
			<searchrunners v-if="activeTab == 0" />
			<newrunners v-if="activeTab == 1" />
			<updatedrunners v-if="activeTab == 2" />
			<approvedrunners v-if="activeTab == 3" />
			<rejectedrunners v-if="activeTab == 4" />
			<diswinners v-if="activeTab == 5" />
			<ldwinners v-if="activeTab == 6" />
		</div>
	</div>
</template>

<script>
	import newrunners from "../components/Runners/new.vue";
	import searchrunners from "../components/Runners/search.vue";
	import updatedrunners from "../components/Runners/updated.vue";
	import approvedrunners from "../components/Runners/approved.vue";
	import rejectedrunners from "../components/Runners/rejected.vue";
	import ldwinners from "../components/Runners/winners.vue";
	import diswinners from "../components/Runners/distance.vue";
	export default {
		data() {
			return {
				activeTab: 0,
				tabs: ["All", "Pending", "Updated"],
			};
		},
		components: {
			newrunners,
			updatedrunners,
			approvedrunners,
			rejectedrunners,
			searchrunners,
			ldwinners,
			diswinners,
		},
	};
</script>

<style>
	tr {
		border-bottom: 1px solid #283547;
	}
</style>
