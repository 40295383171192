<template>
	<div class="block w-full overflow-x-auto">
		<viewimg v-if="img" :img="img" :closeimg="closeimg" />
		<div class="flex">
			<input
				v-model="search"
				class="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				type="text"
				placeholder="Sequence no or ID"
				autofocus
			/>
			<button
				@click="searchFn"
				class="bg-blue-700 mb-4 hover:bg-blue-900 text-white font-bold px-4 rounded ml-4"
			>
				Search
			</button>
		</div>
		<table class="items-center bg-transparent w-full border-collapse">
			<thead>
				<tr>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Sequence no
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						ID
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Name
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Contact
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Pending Distance
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Approved Distance
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Screenshot
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Submit Time
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Status
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Action
					</th>
				</tr>
			</thead>
			<tbody v-if="items">
				<tr v-for="(item, i) in items" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.bib }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.nid }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.name }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.contact }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<template v-if="item.distance > 0">
							<input
								class="bg-blue-900 w-1/2 mr-1"
								v-model="item.distance"
								type="number"
							/>
							<span class="mr-1">of</span>
							<span>{{
								new Intl.NumberFormat("en-US").format(
									Number(item.pendingDistance).toFixed(2)
								)
							}}</span>
						</template>

						<template v-else>
							{{
								new Intl.NumberFormat("en-US").format(
									Number(item.distance).toFixed(2)
								)
							}}
						</template>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{
							new Intl.NumberFormat("en-US").format(
								Number(item.approvedDistance).toFixed(2)
							)
						}}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<template
							v-if="
								item.screenshot &&
								Array.isArray(item.screenshot)
							"
						>
							<p
								v-for="(s, i) in item.screenshot"
								class="cursor-pointer"
								:key="s"
								@click="img = s"
							>
								Screenshot {{ item.screenshot.length - i }}
							</p>
						</template>
						<span
							class="cursor-pointer"
							@click="img = item.screenshot"
							v-else-if="item.screenshot"
							>Screenshot 1</span
						>
						<span v-else>--</span>
					</th>
					<th
						v-if="item.submittime"
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ new Date(item.submittime).getFullYear() }}-{{
							new Date(item.submittime).getMonth() + 1
						}}-{{ new Date(item.submittime).getDate() }}
						{{ new Date(item.submittime).getHours() }}:{{
							new Date(item.submittime).getMinutes()
						}}
					</th>
					<th
						v-else
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						--
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{
							item.state == "new"
								? "Pending"
								: item.state == "updated"
								? "Updated"
								: item.state == "approved"
								? "Approved"
								: item.state == "reject"
								? "Rejected"
								: "Unknown"
						}}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<button
							v-if="
								item.state !== 'new' &&
								item.state !== 'approved'
							"
							@click="approve(item.nid, item.bib, item.distance)"
							class="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded mr-4"
						>
							Approve
						</button>
						<button
							v-if="
								item.state !== 'new' &&
								item.state !== 'reject' &&
								item.distance > 0
							"
							@click="reject(item.nid, item.bib, item.distance)"
							class="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded"
						>
							Reject
						</button>
					</th>
				</tr>
			</tbody>
			<tbody v-else>
				<tr class="animate-pulse" v-for="i in 10" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
				</tr>
			</tbody>
		</table>
		<div v-if="items && items.length < 1" class="text-center">
			Wow, such empty
		</div>
		<div v-if="peginate && peginate.totalitems">
			Total: {{ peginate.totalitems }}
		</div>
		<pagination v-if="peginate" :items="peginate" :onc="getPage" />
	</div>
</template>

<script>
	import pagination from "../Common/pegination.vue";
	import viewimg from "./viewimg.vue";
	export default {
		data() {
			return {
				state: "new",
				items: null,
				peginate: null,
				img: null,
				cpageno: 1,
				search: null,
				timeout: null,
			};
		},
		watch: {
			search: function () {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(this.searchFn, 200);
			},
		},
		mounted() {
			console.log(this.state);
			this.getPage(1);
		},
		methods: {
			searchFn() {
				let url = process.env.VUE_APP_API_ROOT + "/search?limit=10";
				if (this.state) {
					url = url + "&state=" + this.state;
				}
				if (this.search) {
					this.$http
						.get(url + "&search=" + this.search)
						.then(this.handleItems)
						.catch(this.handleerr);
				} else {
					let url = process.env.VUE_APP_API_ROOT + "/runner?limit=10";
					if (this.state) {
						url = url + "&state=" + this.state;
					}
					this.$http
						.get(url + "&page=" + this.cpageno)
						.then(this.handleItems)
						.catch(this.handleerr);
				}
			},
			handleItems(items) {
				if (items.status == 200) {
					this.items = items.data.items;
					this.peginate = items.data.meta;
					if (
						this.cpageno > items.data.meta.totalpage &&
						this.cpageno !== 1
					) {
						this.cpageno = items.data.meta.totalpage;
						this.getPage();
					}
				}
			},
			handleerr(err) {
				console.log(err);
			},
			getPage(pageno = null) {
				this.items = null;
				if (pageno) this.cpageno = pageno;
				if (this.search) {
					let url = process.env.VUE_APP_API_ROOT + "/search?limit=10";
					if (this.state) {
						url = url + "&state=" + this.state;
					}
					this.$http
						.get(
							url +
								"&search=" +
								this.search +
								"&page=" +
								this.cpageno
						)
						.then(this.handleItems)
						.catch(this.handleerr);
				} else {
					let url = process.env.VUE_APP_API_ROOT + "/runner?limit=10";
					if (this.state) {
						url = url + "&state=" + this.state;
					}
					this.$http
						.get(url + "&page=" + this.cpageno)
						.then(this.handleItems)
						.catch(this.handleerr);
				}
			},
			closeimg() {
				this.img = null;
			},
			approve(nid, bib, approveDistance) {
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/approve", {
						nid,
						bib,
						approveDistance,
					})
					.then(this.handleaction)
					.catch(this.handleerr);
				this.getPage();
			},
			reject(nid, bib, rejectDistance) {
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/reject", {
						nid,
						bib,
						rejectDistance,
					})
					.then(this.handleaction)
					.catch(this.handleerr);
				this.getPage();
			},
			handleaction() {
				this.getPage();
			},
		},
		components: {
			pagination,
			viewimg,
		},
	};
</script>
