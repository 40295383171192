<template>
	<div class="block w-full overflow-x-auto">
		<viewimg v-if="img" :img="img" :closeimg="closeimg" />
		<table class="items-center bg-transparent w-full border-collapse">
			<thead>
				<tr>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Sequence no
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						ID
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Name
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Contact
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Distance run
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Screenshot
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Submit Time
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Status
					</th>
					<th
						class="px-6 bg-blue-900 text-blue-100 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
					>
						Action
					</th>
				</tr>
			</thead>
			<tbody v-if="items">
				<tr v-for="(item, i) in items" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.bib }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.nid }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.name }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.contact }}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ item.distance }}
					</th>
					<th
						@click="img = item.screenshot"
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<button v-if="item.screenshot">View</button>
						<span v-else>--</span>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						{{ new Date(item.submittime).getFullYear() }}-{{
							new Date(item.submittime).getMonth() + 1
						}}-{{ new Date(item.submittime).getDate() }}
						{{ new Date(item.submittime).getHours() }}:{{
							new Date(item.submittime).getMinutes()
						}}
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						Rejected
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<button
							@click="approve(item.nid, item.bib)"
							class="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded mr-4"
						>
							Approve
						</button>
					</th>
				</tr>
			</tbody>
			<tbody v-else>
				<tr class="animate-pulse" v-for="i in 10" :key="i">
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
					<th
						class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blue-100"
					>
						<div class="h-2 bg-gray-700 rounded col-span-2"></div>
					</th>
				</tr>
			</tbody>
		</table>
		<div v-if="items && items.length < 1" class="text-center">
			Wow, such empty
		</div>
		<div v-if="peginate && peginate.totalitems">
			Total: {{ peginate.totalitems }}
		</div>
		<pagination v-if="peginate" :items="peginate" :onc="getPage" />
	</div>
</template>

<script>
	import pagination from "../Common/pegination.vue";
	import viewimg from "./viewimg.vue";
	export default {
		data() {
			return {
				items: null,
				peginate: null,
				img: null,
				cpageno: 1,
			};
		},
		mounted() {
			this.$http
				.get(
					process.env.VUE_APP_API_ROOT +
						"/runner?state=reject&limit=10"
				)
				.then(this.handleItems)
				.catch(this.handleerr);
		},
		methods: {
			handleItems(items) {
				if (items.status == 200) {
					this.items = items.data.items;
					this.peginate = items.data.meta;
					if (
						this.cpageno > items.data.meta.totalpage &&
						this.cpageno !== 1
					) {
						this.cpageno = items.data.meta.totalpage;
						this.getPage();
					}
				}
			},
			handleerr(err) {
				console.log(err);
			},
			getPage(pageno = null) {
				this.items = null;
				if (pageno) this.cpageno = pageno;
				this.$http
					.get(
						process.env.VUE_APP_API_ROOT +
							"/runner?state=reject&limit=10&page=" +
							this.cpageno
					)
					.then(this.handleItems)
					.catch(this.handleerr);
			},
			closeimg() {
				this.img = null;
			},
			approve(nid, bib) {
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/approve", {
						nid,
						bib,
					})
					.then(this.handleaction)
					.catch(this.handleerr);
				this.getPage();
			},
			reject(nid, bib) {
				this.$http
					.post(process.env.VUE_APP_API_ROOT + "/reject", {
						nid,
						bib,
					})
					.then(this.handleaction)
					.catch(this.handleerr);
				this.getPage();
			},
			handleaction() {
				this.getPage();
			},
		},
		components: {
			pagination,
			viewimg,
		},
	};
</script>
